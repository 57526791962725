@mixin vendor-prefix($property, $value) {
    #{$property}: $value;

    @each $prefix in("webkit", "moz", "ms", "o") {
        #{'-' + $prefix + '-' + $property}: $value;
    }
}

@mixin vendor-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

.ads6ix-layout {
    &-header {
        z-index: 999;
        position: relative;

        &-inner {
            padding: 0px 15px;
            height: 52px;

            @media (max-width: 767px) {
                padding: 0px 8px;
                font-size: 13px;
            }
        }

        &-top {
            background-color: black;
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 7px 24px;
            font-size: 14px;

            @media (max-width: 767px) {
                padding: 8px 8px;
                font-size: 13px;
            }

            &-text {
                color: #e9e9e9;
                font-weight: 700;
            }

            &-link {
                color: #e3c050;
            }
        }

        &-left {
            gap: 10px;

            @media (max-width: 767px) {
                gap: 5px;
                padding-left: 0px;
            }
        }

        &-menuButton {
            padding: 0;
            cursor: pointer;

            &:hover {
                svg {
                    path {
                        &[stroke] {
                            stroke: white;
                        }

                        &[fill] {
                            fill: white;
                        }
                    }
                }
            }
        }

        &-lineV {
            width: 1px;
            height: 24px;
            background-color: #373737;
        }

        &-logo {
            padding: 0px 5px;
            display: flex;

            a {
                display: inline-flex;
            }

            &-img {
                max-width: 100%;
                height: 24px;
                max-height: 100%;
            }

            &-text {
                font-size: 13px;
                color: #e3c050;
                margin: auto -5px 0px -15px;
                padding-right: 5px;
            }
        }

        &-right {
            gap: 10px;

            @media (max-width: 767px) {
                gap: 5px;
            }

            &-item {
                position: relative;

                &-btn {
                    cursor: pointer;
                    color: #C0C0C0;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 125%;
                    text-align: center;
                    width: 42px;
                    height: 42px;
                    @include vendor-prefix(border-radius, 8px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid transparent;



                    svg {
                        margin: auto auto 0;
                    }

                    &-text {
                        margin-top: 5px;
                        margin-bottom: auto;
                    }

                    &.active,
                    &:hover {
                        border-color: #e3c050;
                        background-color: #1F1F1F;
                        color: #e9e9e9;

                        svg {

                            path,
                            rect {
                                &[fill] {
                                    fill: #e9e9e9;
                                }

                                &[stroke] {
                                    stroke: #e9e9e9;
                                }
                            }
                        }
                    }

                    &-user {
                        width: 30px;
                        height: 30px;
                        @include vendor-prefix(border-radius, 50px);
                        overflow: hidden;
                        margin: auto;
                        display: flex;
                        background-color: #2B2B2B;

                        svg {
                            margin: auto;
                        }

                        img {
                            object-fit: cover;
                        }
                    }

                    &-full {
                        width: auto;
                        font-size: 16px;
                        flex-direction: row;
                        padding-left: 12px;
                        padding-right: 12px;
                        font-weight: normal;

                        svg {
                            margin: 0px 0px 0px 6px !important;
                        }

                        @media(max-width: 1024px) {
                            font-size: 14px;
                            padding-left: 6px;
                            padding-right: 6px;
                        }
                    }

                    &-full & {
                        &-text {
                            margin: 0px;
                        }
                    }
                }

                &-dropdown {
                    .MuiPaper-root {
                        @include vendor-prefix(border-radius, 11px);
                        border: 1px solid #373737;
                        background: #1F1F1F;
                        box-shadow: 0px 4px 50px 0px #131313;
                        margin-top: 14px;
                        min-width: 200px;
                        max-width: 90%;
                        max-height: calc(100% - 105px);
                    }

                    &-language {
                        &-header {
                            color: #575757;
                            padding: 8px 8px;
                            font-size: 15px;
                        }

                        .MuiPaper-root {
                            width: 500px;
                            padding: 0px 8px;
                        }

                        &-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        &-item {
                            color: #C0C0C0 !important;
                            font-size: 14px !important;
                            width: 33.33%;
                            @include vendor-prefix(border-radius, 8px !important);
                            padding: 4px !important;

                            &-check {
                                width: 15px;

                                svg {
                                    width: 10px;
                                    height: auto;
                                }
                            }

                            &.active {
                                color: #e3c050 !important;
                            }

                            @media (max-width: 767px) {
                                font-size: 13px !important;
                            }
                        }
                    }

                    &-apps {
                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: #e9e9e9;
                            border-bottom: 1px solid #2B2B2B;
                        }

                        &-list {
                            padding-left: 8px !important;
                            padding-right: 8px !important;

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;

                                span {
                                    font-size: 16px;
                                    color: #e3c050;
                                }

                                p {
                                    font-size: 14px;
                                    color: #8c8e95;
                                }

                                @media (max-width: 767px) {
                                    gap: 5px;

                                    span {
                                        font-size: 15px;
                                    }

                                    p {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }

                    &-users {
                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: #e9e9e9;
                            border-bottom: 1px solid #2B2B2B;
                        }

                        &-list {
                            padding: 0px 8px !important;

                            &-divider {
                                height: 1px;
                                background-color: #2B2B2B;
                                margin: 8px -8px;
                            }

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;
                                font-size: 14px;
                                color: #C0C0C0 !important;

                                &.MuiListItemButton-root {
                                    text-align: left;
                                    padding-top: 8px;
                                    padding-bottom: 8px;
                                    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    padding-left: 16px;
                                    padding-right: 16px;
                                    background-color: transparent;
                                    outline: 0;
                                    border: 0;
                                    margin: 0;
                                    cursor: pointer;
                                    user-select: none;
                                    -webkit-appearance: none;
                                    display: flex;
                                    flex-grow: 1;
                                    justify-content: flex-start;
                                    align-items: center;
                                    position: relative;
                                    text-decoration: none;
                                    min-width: 0;
                                    box-sizing: border-box;
                                }


                                span {
                                    font-size: 14px;
                                }

                                &:hover {
                                    color: #e9e9e9 !important;
                                }
                            }
                        }
                    }
                }

                &-accounts {
                    padding-bottom: 50px;

                    &-btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        position: relative;
                        padding-right: 10px;
                        max-width: 230px;
                        cursor: pointer;
                        color: #C0C0C0;
                        font-size: 14px;
                        font-weight: 600;
                        border: 1px solid transparent;
                        height: 42px;
                        @include vendor-prefix(border-radius, 8px);
                        padding: 0px 10px;

                        @media (max-width: 767px) {
                            padding: 0px 5px;
                            gap: 5px;
                        }

                        &-logo {
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #373737;
                            border: 1px solid #575757;
                            @include vendor-prefix(border-radius, 50%);
                            overflow: hidden;
                            flex: none;

                            img {
                                object-fit: cover;
                            }
                        }

                        &-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (max-width: 767px) {
                                max-width: 70px;
                                font-size: 13px;
                            }
                        }

                        &.active,
                        &:hover {
                            border-color: #e3c050;
                            background-color: #1F1F1F;
                            color: #e9e9e9;

                            svg path {
                                &[fill] {
                                    fill: #e9e9e9;
                                }

                                &[stroke] {
                                    stroke: #e9e9e9;
                                }
                            }
                        }
                    }

                    &-list {
                        padding: 0px 8px !important;
                        margin-bottom: 10px !important;

                        &-loader {
                            padding: 20px;
                            text-align: center;
                        }

                        &-item {
                            @include vendor-prefix(border-radius, 11px !important);
                            gap: 10px !important;
                            font-size: 14px;
                            color: #C0C0C0 !important;
                            padding: 8px 10px !important;

                            &-logo {
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #373737;
                                border: 1px solid #575757;
                                @include vendor-prefix(border-radius, 50%);
                                overflow: hidden;
                                flex: none;

                                img {
                                    object-fit: cover;
                                }
                            }

                            &-name {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &-check {
                                margin-left: auto;

                                svg {
                                    width: 14px;
                                    height: auto;
                                }
                            }

                            &:hover {
                                color: #e9e9e9 !important;
                            }
                        }
                    }

                    &-footer {
                        padding: 12px 20px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #C0C0C0;
                        border-top: 1px solid #2B2B2B;
                        position: sticky;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        right: 0;
                        justify-content: center;
                        gap: 5px;
                        background-color: #1f1f1f;

                        &:hover {
                            color: #e3c050;

                            svg path {
                                &[fill] {
                                    fill: #e3c050;
                                }

                                &[stroke] {
                                    stroke: #e3c050;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    &-sidebar {
        position: fixed;
        left: -100%;
        @include vendor-prefix(transition, 0.3s);
        width: 280px;
        height: calc(100% - 87px);
        background-color: #1F1F1F;
        z-index: 9999999;
        top: 87px;
        padding: 10px;

        &-space {
            top: 102px;
        }

        &.active {
            left: 0%;
        }

        &-inner {
            height: 100%;
            @include vendor-prefix(border-radius, 11px);
            box-shadow: 0px 4px 50px 0px #131313;

        }

        &-body {
            padding: 16px 0px;
            overflow: auto;
        }

        &-header {
            color: #575757;
            font-size: 12px;
            line-height: 135%;
            text-transform: uppercase;
            padding: 0px 20px 10px;
        }

        &-link {
            padding: 8px 20px;
            color: #C0C0C0;
            font-size: 16px;
            line-height: 135%;
            display: flex;
            align-items: center;
            @include vendor-prefix(border-radius, 8px);
            text-decoration: none;



            &.active {
                background: #2B2B2B;
                color: white;
            }

            &:not(.active):hover {
                color: #e3c050;
            }
        }

        &-divider {
            background-color: #2B2B2B;
            height: 1px;
            width: 100%;
            margin: 16px 0px;
        }

        &-overlay {
            height: 100vh;
            position: fixed;
            z-index: 999999;
            cursor: pointer;
            width: 100%;
        }

    }
}